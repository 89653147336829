<template>
    <div>
        <div v-if="visible">
            <el-page-header @back="back" content="产废明细"></el-page-header>
            <div ref="chart" style="width: 100%; height: 500px;"></div>
            <div>产废企业: {{ cName }}, 统计年份: {{ year }}, 总净重: {{ sum }}kg</div>
            <div class="jy-table">
                <jy-table :data="dataList">
                    <jy-table-column type="index" label="序号" width="60"></jy-table-column>
                    <jy-table-column label="产废企业">
                        <template v-slot="scope">{{ cName }}</template>
                    </jy-table-column>
                    <jy-table-column label="统计日期">
                        <template v-slot="scope">{{ scope.row.date }}</template>
                    </jy-table-column>
                    <jy-table-column label="净重(kg)">
                        <template v-slot="scope">{{ scope.row.data.sum }}</template>
                    </jy-table-column>
                    <jy-table-column label="上一年同期(kg)">
                        <template v-slot="scope">{{ scope.row.data.sumPre }}</template>
                    </jy-table-column>
                    <jy-table-column label="与上一年同期变化">
                        <template v-slot="scope">{{ formatPercent(scope.row.data.percent) }}%</template>
                    </jy-table-column>
                </jy-table>
            </div>
        </div>
    </div>
</template>

<script>
import * as Echarts from 'echarts';
export default {
    props: {
        visible: Boolean,
        cName: String,
        year: String,
        dataList: Array,
        back: Function
    },
    data() {
        return {
            option: null,
        }
    },
    updated() {
        if (this.$refs.chart) {
            this.initOption();
            const chart = Echarts.init(this.$refs.chart);
            chart.setOption(this.option);
        }
    },
    methods: {
        formatPercent(percent) {
            const res = percent * 100;
            if (res > 0) {
                return '+' + res.toString();
            }
            return res.toString();
        },
        initOption() {
            const category = this.dataList.map(v => v.date);
            const data = this.dataList.map(v => v.data.sum);
            this.option = {
                xAxis: {
                    name: '日期',
                    type: 'category',
                    data: category
                },
                yAxis: {
                    name: 'kg',
                    type: 'value',
                },
                series: [
                    {
                        data,
                        type: 'bar',
                    }
                ]
            }
        },
    },
    computed: {
        sum() {
            return this.dataList.reduce((prev, cur) => {
                return prev + cur.data.sum;
            }, 0)
        }
    }
}
</script>